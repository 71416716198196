// Custom colors
$blue: #0784e9;
$body-color: #303030;

// Bootstrap
// Bootstrap - required
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

$font-family-base: "Open Sans", sans-serif;
$enable-responsive-font-sizes: true;